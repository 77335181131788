import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import { Link } from "../link"
import pageUrl from "../../utility/page-url"


const Breadcrumb = ({ page, breadcrumb, className }) => {
  const breadcrumbList = useMemo(() => {
    if (breadcrumb) return breadcrumb;
    if (page) {
      const recurseParent = (page, breadcrumbList) => {
        const parentPage = page.liferayParentPage;
        if (parentPage) {
          return recurseParent(parentPage, [{ link: `/${pageUrl(parentPage)}`, label: parentPage.pageTitle.value }].concat(breadcrumbList));
        }
        return [{ link: '/', label: 'Home' }].concat(breadcrumbList);
      }
      return recurseParent(page, [{ label: page.pageTitle.value }]);
    }
    return null;
  }, [page, breadcrumb]);

  return (
    <React.Fragment>
      {breadcrumbList ? (
        <div className="row">
          <div className={`col-12 breadcrumb d-none d-md-block pt-3${className ? ` ${className}`: ''}`}>
            {breadcrumbList.map((item, i) => (
              <React.Fragment key={i}>
                {item.link ? <Link to={item.link} className="">{item.label}</Link> : <span>{item.label}</span>}
                {i < breadcrumbList.length - 1 ? <span className="breadcrumb__sep"></span> : null}
              </React.Fragment>
            ))}
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
}

export default Breadcrumb;
export const breadcrumbFragment = graphql`
fragment DetailBreadcrumbPaginaInternaFragment on LiferayPaginainterna {
  langCode
  pageTitle {
    value
    content {
      friendlyUrl{
        value
      }
    }
  }
}
fragment DetailBreadcrumbPaginaInternaFragment2 on LiferayPaginainterna {
  ... DetailBreadcrumbPaginaInternaFragment
  liferayParentPage {
    ... DetailBreadcrumbPaginaInternaFragment
    ... DetailBreadcrumbPaginanewsroomFragment
  }
}
fragment DetailBreadcrumbPaginanewsroomFragment on LiferayPaginanewsroom {
  langCode
  pageTitle {
    value
    content {
      friendlyUrl{
        value
      }
    }
  }
}
fragment DetailBreadcrumbPaginanewsroomFragment2 on LiferayPaginanewsroom {
  ... DetailBreadcrumbPaginanewsroomFragment
  liferayParentPage {
    ... DetailBreadcrumbPaginaInternaFragment
    ... DetailBreadcrumbPaginanewsroomFragment
  }
}
fragment BreadcrumbPaginainternaFragment on LiferayPaginainterna{
  pageTitle {
    value
  }
  liferayParentPage {
    ... on LiferayPaginainterna {
      ... DetailBreadcrumbPaginaInternaFragment
      liferayParentPage {
        ... DetailBreadcrumbPaginaInternaFragment2
        ... DetailBreadcrumbPaginanewsroomFragment2
      }
    }
    ... on LiferayPaginanewsroom {
      ... DetailBreadcrumbPaginanewsroomFragment
      liferayParentPage {
        ... DetailBreadcrumbPaginaInternaFragment2
        ... DetailBreadcrumbPaginanewsroomFragment2
      }
    }
  }
}
fragment BreadcrumbPaginanewsroomFragment on LiferayPaginanewsroom{
  pageTitle {
    value
  }
  liferayParentPage {
    ... on LiferayPaginainterna {
      ... DetailBreadcrumbPaginaInternaFragment
      liferayParentPage {
        ... DetailBreadcrumbPaginaInternaFragment2
        ... DetailBreadcrumbPaginanewsroomFragment2
      }
    }
    ... on LiferayPaginanewsroom {
      ... DetailBreadcrumbPaginanewsroomFragment
      liferayParentPage {
        ... DetailBreadcrumbPaginaInternaFragment2
        ... DetailBreadcrumbPaginanewsroomFragment2
      }
    }
  }
}`;
