import React from 'react'
import PreviewItem from '../components/preview-item'
// Preview HOC https://reactjs.org/docs/higher-order-components.html

// This function takes a component...
const withPreview = (WrappedComponent, options = {}) => {
  // ...and returns another component...
  if (!WrappedComponent) return null;
  const { subField, fixed } = options;
  return ({ data, ...other }) => {
    const fieldsData = subField ? data[subField]: data;
    return (
      <React.Fragment>
        {process.env.NODE_ENV === 'development' && fieldsData.siteId && fieldsData.articleId ? (
          <PreviewItem fascia={fieldsData} angle={fieldsData.__typename === 'LiferayLanci'} fixed={fixed} >
            <WrappedComponent data={data} {...other} />
          </PreviewItem>
        ) : <WrappedComponent data={data} {...other} />}
      </React.Fragment>
    );
  }
}

export default withPreview;